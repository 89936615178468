.Enquiry {
  max-width: 500px;
  .message {
    height: 10rem;
  }

  .btn {
    .Spinner {
      font-size: inherit;
    }
  }

  .isSent {
    font-size: 24px;
    font-weight: 350;
  }
}
