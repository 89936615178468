.Order {
  $color: #6c6f72;
  margin-bottom: 1em;

  .products,
  .features {
    label {
      display: block;
      text-align: center;

      input[type="checkbox"],
      input[type="radio"] {
        display: none;

        ~ img {
          border: solid 1px transparent;
          padding: 5px;
        }

        ~ span {
          color: $color;
          padding: 1em 0;
          line-height: 1.5;
          display: block;
        }

        &:checked {
          ~ img {
            border-color: $color;
          }

          ~ span {
            color: black;
          }
        }

        &:disabled {
          ~ span {
            color: #5cb951;
          }

          ~ img {
            border-color: #5cb951;
          }
        }
      }
    }
  }

  .control-label.optional {
    &::after {
      content: "(optional)";
      margin-left: 0.5em;
      color: #888;
      font-size: 90%;
    }
  }

  .products img {
    max-width: 162px;
  }

  .features img {
    max-width: 80px;
  }

  .expiryPeriod {
    display: flex;
    flex-flow: row, nowrap;
    margin-top: 40px;

    input {
      margin-top: 8px;
      margin-left: 5rem;
      vertical-align: middle;
    }
  }
}
