.Breadcrumbs {
  font-weight: 500;
  display: flex;
  background: #eee;

  $color: #58a2e4;

  margin: 0 0 1rem;
  padding: 0;
  list-style: none;
  font-weight: 500;
  line-height: 33px;

  li {
    &:first-child {
      a, span {
        padding-left: 10px;
      }
    }
  }

  a, span {
    display: inline-block;
    height: 36px;
    padding: 0 10px 0 20px;
  }

  a {
    color: white;
    background: $color;
    position: relative;

    &::after {
      content: "";
      border-right: solid;
      position: absolute;
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      right: -14px;
      z-index: 2;
      border-left: 11px solid darken($color, 10%);
      color: transparent;
    }

    &:hover {
      text-decoration: none;
    }
  }

  span {
    vertical-align: middle;
  }
}
