.Home {
  .media {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .media-box {
    text-align: center;
    margin-bottom: 20px;
    min-width: 270px;
  }

  .fa {
    font-size: 50px;
    display: block;
    color: #c0c0c0;
  }

  .fa:hover {
    color: #d33;
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
    color: #4c4c4c;
    margin-top:20px;
    margin-bottom: 20px;
  }
}
