.Toaster {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 1rem;

  a {
    text-decoration: underline;
  }

  > * {
    pointer-events: initial;
  }
}
