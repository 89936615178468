.PasswordReset {
  text-align: center;
  padding: 20px;

   .title {
      font-size: 42px;
      font-weight: 100;
      color: #808080;
    }

  .logo {
    svg {
      height: 80px;
    }

    .IP {
      fill: black;
    }
    margin: 10px
  }
  .input {
    margin:10px 0 ;
    padding: 10px;
  }

  .button {
    margin:10px 0;
   border: 1px solid #d8d8d8;
  }

  .h2 {
    font-weight: 300;
    font-size: 25;
    color: #808080;
  }

   .form {
     border: 1px solid #d8d8d8;
     padding: 40px 20px 20px 20px;
     margin-top: 40px;
   }

  .p {
    font-weight: 400;
    color: gray;
  }

}
