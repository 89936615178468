.PostForm {
  form {
    max-width: 800px;
  }

  .description {
    height: 20rem;
  }

  .preview {
    max-width: 64px;
    max-height: 64px;
  }

  .icon {
    color: #c0c0c0;
    font-size: 60px;
    font-weight: 400;
  }

  .file-upload {
    display: flex;
    align-items: center;

    label {
      margin: 0 0.5rem;
    }
  }
}
