.Header {
  display: flex;
  justify-content: flex-end;

  border-bottom: 1px solid #9d9d9d;
  padding: 1rem .5rem;

  .fa {
    margin-right: .25rem;
  }

  .login-details {
    margin-right: .5rem;
    display: flex;
    align-items: center;
  }
}
