.Login {
  text-align: center;
  padding: 20px;

  .error {
    color: red;
    margin-top: 15px;
  }

  .title {
    font-size: 42px;
    font-weight: 100;
    color: #808080;
  }

  .logo {
    svg {
      height: 80px;
    }

    color: black;
    margin: 10px;
  }

  .header {
    font-weight: 300;
    font-size: 32px;
    color: #808080;
  }

  .form {
    border: 1px solid #d8d8d8;
    padding: 1rem;
    margin-top: 3rem;
  }

  p {
    font-weight: 400;
    color: gray;
  }

  strong {
    font-weight: 400;
    color: black;
  }
}
