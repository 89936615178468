.NavMenu {
  @media (min-width: 576px) {
    .navbar {
      width: calc(25% - 20px);
      bottom: 0;

      flex-flow: column;
      justify-items: flex-start;
      align-items: stretch;
    }

    .navbar-collapse {
      border-top: 1px solid #444;

      flex-flow: column;
      align-items: stretch;
    }

    .navbar-collapse:not(.show) {
      display: flex;
    }
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: column;
  }

  .logo {
    svg {
      height: 50px;
    }
    color: white;
  }

  .videoFxBorder{
    border-top:solid #9d9d9d 2px;
  }

 .videoFx {
    height: 45px;
    margin-top: 1rem;
  }

  /* Highlighting rules for nav menu items */
  .navbar .navbar-nav a.active.nav-link,
  .navbar .navbar-nav a:hover.active.nav-link,
  .navbar .navbar-nav a:focus.active.nav-link {
    background-image: none;
    background-color: #4189C7;
    color: white;
  }

  .navbar li {
    font-size: 15px;
    margin: 6px 0;
  }

  .navbar li a {
    padding: 10px 16px;
    border-radius: 4px;
  }

  .navbar .navbar-nav a.active {
    box-shadow: inset 0 3px 9px rgba(0,0,0,.25);
  }

  .navbar .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }

  .navbar li .fa {
    margin-right: 10px;
    width: 1em;
  }

  .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .navbar li a {
    /* If a menu item's text is too long, truncate it */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
