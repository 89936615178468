.SearchBar {
  margin-bottom: 20px;

  .search-button {
    display: flex;
    align-items: center;
  }

  input {
    max-width: 500px;
  }
}
