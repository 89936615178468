$primary: #333745;
@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Play";
  src: local("Play");
  src: url("./fonts/Play-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto");
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}

body {
  overflow-x: hidden;
  margin-right: 20px;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (max-width: 575px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
    padding-top: 80px;
    margin: 0 20px;
  }
}

#root {
  min-height: 100vh;
}

header {
  font-size: 2rem;
  font-weight: 500;
}

h1 {
  font-size: 2rem;
}
p,
h1,
h2,
h3,
header {
  margin-bottom: 0.5em;
  font-family: "Roboto", sans-serif;
}

.btn {
  font-family: "Play", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.btn-primary:hover {
  background-color: #d33;
  border-color: #d33;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}
