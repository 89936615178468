.FilesList {
  .entry {
      padding: .75rem;
     .icon {
        min-width: 2rem;
        color: #666;
        text-align: center;
      }
   }

  .right {
    text-align: right;
  }

  .btn {
    line-height: 1;
  }
}

.alert.FilesListToast {
  color: white;
  background-color: #4189c7;
  border-color: #2c8add;

  a {
    color: inherit;
  }
}
