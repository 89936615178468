.OrderPopup {
  header {
    font-size: medium;
    margin-bottom: 0.5em;
  }

  dl {
    line-height: 1.75;

    dt {
      display: inline-block;
      min-width: 10em;

      &::after {
        content: ": ";
      }
    }

    dd {
      display: inline;

      &::after {
        content: "";
        display: block;
      }
    }
  }

  .skus {
    margin: 0 -0.25em;
  }

  .product {
    .sku {
      background: #ddf;
    }
  }

  .features {
    .sku {
      background: #fdd;
    }
  }

  .sku {
    display: inline-block;
    padding: 2px 9px;
    margin: 0.25em;
    white-space: nowrap;
  }
}
