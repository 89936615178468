.PostsList {
  margin-top: 20px;
  max-width: 1024px;

  .preview {
    width: 99px;
    margin-right: 1rem;
  }

  .post {
    padding-left: 0px;
    display: flex;
    flex-flow: row;
  }

  @media (max-width: 575px) {
    .post {
      flex-wrap: wrap;
    }

    .preview {
      margin-bottom: 1rem;
    }
  }

  a:hover {
    text-decoration: none;
  }
}
