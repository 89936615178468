.Toolbar {
  display: flex;
  flex-flow: row wrap;
  margin-left: -0.5rem;
  margin-top: -0.5rem;

  > * {
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }

  .PushRight {
    margin-left: auto;
  }
}
