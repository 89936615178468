.Support {
  .media {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .media-box {
    text-align: center;
    margin: 20px 5px;
    padding: 30px 10px;
    min-width: 370px;
    min-height: 220px;
    border: 0.5px solid #d3d3d3;
    color: #4c4c4c;

    text-decoration: none;
    background: white;
    cursor: pointer;
  }

  .icon-title {
    margin-top: 20px;
    font-size: 24px;
    font-weight: 350;
  }

  .red {
    color: #ee2e24;
  }
}
